import { memo, useState, useCallback, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'

import './StageForm.scss'
import StageGroup from '../StageGroup/StageGroup'
import {
	selectProcessedStage,
	selectPlayersList,
	selectTournamentOptions,
	selectStageCondition,
	selectImmutableProcessedLevels,
	selectInitialStage,
	selectForbiddenMessage,
} from '../../../redux/selectors'
import {
	useFillStageMutation,
	useFillAutoStageMutation,
	useClearStageMutation
} from '../../../redux/stageService/stageApiSlice'
import { getParameters, checkEquality, checkParticipantPlace } from '../../../utils/functions'
import { formNodesForRequest, levelsHaveGroups, matchesAreStarted } from '../../../utils/functions2'
import {
	setPlayersList,
	setProcessedStage,
	setDragState,
	dragParticipant,
	setChosenPlayerInList,
	setShowForbiddenMessage
} from '../../../redux/stageService/stageSlice'
import RoundedButton from '../../../reusableComponents/RoundedButton/RoundedButton'
import DoubleButton from '../../../reusableComponents/DoubleButton/DoubleButton'
import NoGroups from '../../../reusableComponents/NoGroups/NoGroups'
import NoContent from '../../../reusableComponents/NoContent/NoContent'
import Popup from '../../../reusableComponents/Popup/Popup'
import { FILLED } from '../../../utils/constants'

import {
	dragItems,
	checkNodesEquality
} from './externalFunctions'

function StageForm() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const params = useParams()
	const wildcardValue = params['*']
	const { tournamentParams, stageNumber } = params
	const { tournamentUid } = getParameters(tournamentParams)

	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const { stageStatus } = useSelector(selectStageCondition)
	const initialStage = useSelector(selectInitialStage)
	const { levels = [] } = useSelector(selectProcessedStage)
	const playersList = useSelector(selectPlayersList)
	const immutableLevels = useSelector(selectImmutableProcessedLevels)
	const showForbiddenMessage = useSelector(selectForbiddenMessage)

	const [fillStage] = useFillStageMutation()
	const [fillAutoStage] = useFillAutoStageMutation()
	const [fillClearStage] = useClearStageMutation()

	const [requestIsActive, setRequestIsActive] = useState({
		refillStage: false,
		cleanGroup: false
	})

	useEffect(() => {
		// при открытии вкладки сбрасываем выбранного игрока из списка участников
		dispatch(setChosenPlayerInList({}))
	}, [])

	const isEquale = useMemo(() => {
		return checkNodesEquality(levels, immutableLevels)
	}, [immutableLevels, levels])

	const onDragEnd = useCallback((result) => {
		dispatch(setDragState({}))

		if (!result.destination || checkEquality(result.destination, result.source)) {
			return
		}

		const [endLevelIndex, endGroupIndex] = result.destination.droppableId.split('-')
		const endGroup = { ...levels[endLevelIndex]?.groups[endGroupIndex] }
		const matchesAreStartedInEndGroup = matchesAreStarted(endGroup)

		const [sourceLevelIndex, sourceGroupIndex] = result.source.droppableId.split('-')
		const startGroup = { ...levels[sourceLevelIndex]?.groups[sourceGroupIndex] }
		const matchesAreStartedInStartGroup = matchesAreStarted(startGroup)

		if (matchesAreStartedInEndGroup || matchesAreStartedInStartGroup) {
			dispatch(setShowForbiddenMessage(true))
			return
		}

		const { index } = result.destination
		const replacedNode = { ...levels[endLevelIndex]?.groups[endGroupIndex]?.nodes[index] }

		if (replacedNode?.disabled || Object.keys(replacedNode).length === 0) {
			return
		}

		const object = dragItems(result, levels)
		dispatch(dragParticipant(object))
	}, [levels, dispatch])

	function onBeforeDragStart(result) {
		dispatch(setDragState(result))

		const [sourceLevelIndex, sourceGroupIndex] = result.source.droppableId.split('-')
		const startGroup = { ...levels[sourceLevelIndex]?.groups[sourceGroupIndex] }
		const matchesAreStartedInGroup = matchesAreStarted(startGroup)

		if (matchesAreStartedInGroup) {
			dispatch(setShowForbiddenMessage(true))
			return
		}
	}

	// Функция очищающая группы
	async function cleanGroups() {
		setRequestIsActive(prev => { return { ...prev, cleanGroup: true } })

		try {
			const response = await fillClearStage({
				tournament_uid: tournamentUid,
				stageNumber: stageNumber
			}).unwrap()

			if (response?.error) {
				console.log(`ClearStage return error: ${response?.error}`)
			}

			setRequestIsActive(prev => { return { ...prev, cleanGroup: false } })
		} catch (error) {
			console.log(`fillClearStage catch error: ${error}`)
			setRequestIsActive(prev => { return { ...prev, cleanGroup: false } })
		}
	}

	// Функция возвращающая группы по умолчанию
	function returnGroups() {
		const stageState = { ...initialStage, levels: immutableLevels }
		const updatedParticipantsList = playersList.map((player) => {
			return {
				...player,
				placed: checkParticipantPlace(stageState, player, true)
			}
		})

		dispatch(setPlayersList(updatedParticipantsList))
		dispatch(setProcessedStage(stageState))
	}

	const handleSaveFormedStage = useCallback(async () => {
		try {
			const readyNodes = formNodesForRequest(levels, initialStage, playersList)

			await fillStage({
				tournament_uid: tournamentUid,
				stageNumber: stageNumber,
				body: readyNodes
			}).unwrap()
		} catch (error) {
			console.log(error)
		}
	}, [levels, stageNumber,
		tournamentUid, fillStage, playersList])

	const refillStageSubmit = (async () => {
		setRequestIsActive(prev => { return { ...prev, refillStage: true } })

		try {
			await fillAutoStage({ tournament_uid: tournamentUid, stageNumber }).unwrap()
			setRequestIsActive(prev => { return { ...prev, refillStage: false } })
		}
		catch (e) {
			setRequestIsActive(prev => { return { ...prev, refillStage: false } })
			console.log('refillStageSubmit catch error', e)
		}
	})

	return (
		<div className="stage-form" style={{ marginBottom: !isEquale ? '65px' : '' }}>
			{stageStatus === FILLED ?
				<>
					<DragDropContext
						onDragEnd={(result) => onDragEnd(result)}
						onDragUpdate={(result) => dispatch(setDragState(result))}
						onBeforeDragStart={onBeforeDragStart}
					>
						{
							levels?.map((level, levelIndex) =>
								<div
									key={levelIndex}
									className="stage-form__level"
								>
									{
										levels?.length > 1 &&
										<div className="stage-form__level-content">
											<p className="stage-form__level-name">
												{
													level.name === null ?
														`${t('Level').toUpperCase()} ${level?.order_number}`
														:
														level.name
												}
											</p>
										</div>
									}
									{level?.groups.length > 0 ?
										<>
											{
												level?.groups?.map((group, groupIndex) =>
													<StageGroup
														key={group?.name + groupIndex}
														levelIndex={levelIndex}
														groupIndex={groupIndex}
													/>
												)
											}
										</>
										:
										<NoGroups />
									}
								</div>
							)
						}

						{
							levels?.length !== 0 && levelsHaveGroups(levels) &&
							<div className="stage-form__button-bottom">
								{options?.groupActions?.cleanGroup &&
									<RoundedButton
										title="Очистить"
										background="grey"
										onClick={cleanGroups}
										loading={requestIsActive.cleanGroup}
									/>
								}

								{options?.groupActions?.fillGroup &&
									<RoundedButton
										title="Заполнить"
										background="grey"
										onClick={() => refillStageSubmit()}
										loading={requestIsActive.refillStage}
									/>
								}
							</div>
						}
					</DragDropContext>

					{
						!isEquale && immutableLevels &&
							!wildcardValue &&
								<DoubleButton
									titleBig="Сохранить"
									arrow
									typeSmall={'button'}
									typeBig={'button'}
									onClickBig={() => handleSaveFormedStage()}
									onClickSmall={() => returnGroups()}
									fixed
								/>
					}
				</>
				:
				<NoContent
					structure
				/>
			}

			{showForbiddenMessage &&
			<div className="stage-form__popup-message">
				<Popup
					title={'Действие недоступно'}
					onClose={() => dispatch(setShowForbiddenMessage(false))}
					close
				>
					<p className="stage-form__popup-message-text">Перемещение и замена игрока невозможны после начала игр в группе</p>
				</Popup>
			</div>
			}
		</div>
	)
}

export default memo(StageForm)