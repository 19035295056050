import { createSlice } from '@reduxjs/toolkit'

const playersSlice = createSlice({
	name: 'players',
	initialState: {
		players: [],
		initPlayers: []
	},
	reducers: {
		setCommunityPlayers(state, action) {
			state.players = action.payload
		},
		setInitPlayers(state, action) {
			state.initPlayers = action.payload
		}
	}
})

export const {
	setCommunityPlayers,
	setInitPlayers
} = playersSlice.actions
export default playersSlice.reducer