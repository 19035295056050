import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Field, Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'

import './TournamentForm.scss'
import { useGetCommunityQuery, useAddTournamentMutation } from '../../redux/communityService/communityApiSlice'
import { useUpdateTournamentMutation } from '../../redux/singleTournamentService/singleTournamentApiSlice'
import {
	TYPE_SINGLES,
	TYPE_DOUBLES,
	POINTS_PERCENTAGE_BETWEEN_DISPUTANTS ,
	POINTS_PERCENTAGE,
	T1,
	POINTS_DIFFERENCE_BETWEEN_DISPUTANTS,
	POINTS_DIFFERENCE
} from '../../utils/constants'
import TournamentMenu from '../../reusableComponents/TournamentMenu/TournamentMenu'
import MySelect from '../../reusableComponents/Select/Select'
import Input from '../../reusableComponents/InputLight/InputLight'
import Button from '../../reusableComponents/Button/Button'
import Popup from '../../reusableComponents/Popup/Popup'
import ReactTextarea from '../../reusableComponents/Textarea/Textarea'
import { selectCommunity, selectShowPrestageRating, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { setShowPrestagerating } from '../../redux/stageService/stageSlice'
import RadioButton from '../../reusableComponents/RadioButtonLight/RadioButtonLight'
import Header from '../../reusableComponents/Header/Header'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Loader from '../../reusableComponents/Loader/Loader'
import Switcher from '../../reusableComponents/Switcher/Switcher'

import tournamentOptions from '../../utils/tournamentOptions.json'

import { tiers } from './tiers'

function TournamentForm() {
	dayjs.extend(customParseFormat)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()
	const wildcardValue = params['*']
	const communityUid = localStorage.getItem('communityUid')
	const {
		uid,
		venue_uid,
		start_date,
		type,
		description,
		round_robin_ranking_system,
		name,
		tier,
		players,
		doubles
	} = useSelector(selectTournament)
	const { options: communityOptions = {}, communityName } = useSelector(selectCommunity)
	const { name: tierName = '' } = useSelector(selectTournamentOptions)
	const showPrestageRating = useSelector(selectShowPrestageRating)

	const [addTournament] = useAddTournamentMutation()
	const [updateTournament] = useUpdateTournamentMutation()

	const { data = {}, error, isLoading } = useGetCommunityQuery({ community_uid: communityUid }, { skip: !communityUid })

	const [requestIsActive, setRequestIsActive] = useState(false)
	const [currentRegim, setCurrentRegim] = useState(!wildcardValue ? T1 : tier)
	const [showMessage, setShowMessage] = useState(false)

	const venues = useMemo(() => {
		const arrayOfObjects = data?.venues?.map((el) => {
			return {
				label: el.name,
				value: el.uid
			}
		})

		arrayOfObjects?.unshift({
			label: t('Not selected'),
			value: ''
		})

		return arrayOfObjects
	}, [data?.venues, t])

	const tournamentVenue = useMemo(() => {
		const foundVenue = data?.venues?.find((item) => {
			return item.uid === venue_uid
		})

		if (foundVenue) {
			return {
				label: foundVenue.name,
				value: foundVenue.uid,
				address_string: foundVenue.address_string
			}
		} else {
			return null
		}
	}, [data?.venues, venue_uid])

	const tournamentTiers = useMemo(() => {
		return tiers?.filter(item => communityOptions?.tournamentTiers?.includes(item.value))
	}, [communityOptions])

	function validateName(name, setFieldError) {
		const str = name.replace(' ', '')

		if (!str) {
			setFieldError('name', t('Enter name'))
			return false
		}

		return true
	}

	function validateDate(date, setFieldError) {
		if (!date) {
			setFieldError('date', t('Enter start date'))
			return false
		}

		return true
	}

	function setPrestageRatingCondition(checked) {
		if (checked) {
			dispatch(setShowPrestagerating({
				uid: uid,
				active: checked
			}))
		} else {
			dispatch(setShowPrestagerating(null))
		}
	}

	function getBody(values) {
		const { name, venue, date, type, roundRobinRankingSystem, description, tier } = values

		return {
			'language_code': data?.language_code,
			'name': name,
			'type': type,
			'venue_uid': venue.value,
			'start_date': date,
			'description': description,
			'community_uid': data?.uid,
			'tier': tier.value,
			'round_robin_ranking_system': roundRobinRankingSystem
		}
	}

	async function handleSubmitCreate(values, setFieldError) {
		if (!communityUid || requestIsActive) return
		setRequestIsActive(true)

		const nameIsValid = validateName(values.name, setFieldError)
		const dateIsValid = validateDate(values.date, setFieldError)

		if (!nameIsValid || !dateIsValid) {
			setRequestIsActive(false)
			return
		}

		setPrestageRatingCondition(values.prestageRating)

		const body = getBody(values)

		try {
			const response = await addTournament(body).unwrap()
			setRequestIsActive(false)

			if (response?.error) {
				return
			}

			if (tournamentOptions[currentRegim]?.stage?.createStageWithoutParticipants) {
				navigate(`/${response?.uid}/draws/1`)
			} else {
				navigate(`/${response?.uid}/participants`)
			}

		} catch (e) {
			setRequestIsActive(false)
			console.log('addTournaments catched error', e)
		}
	}

	// Редактируем уже созданный турнир
	async function handleSubmitEdit(values, setFieldError) {
		if (!communityUid) return
		setRequestIsActive(true)

		const nameIsValid = validateName(values.name, setFieldError)
		const dateIsValid = validateDate(values.date, setFieldError)

		if (!nameIsValid || !dateIsValid) {
			setRequestIsActive(false)
			return
		}

		setPrestageRatingCondition(values.prestageRating)

		const body = getBody(values)

		try {
			const response = await updateTournament({
				community_uid: communityUid,
				tournament_uid: uid,
				body
			}).unwrap()

			setRequestIsActive(false)

			if (response?.error) {
				return
			}

			navigate(-1)
		}
		catch (e) {
			setRequestIsActive(false)
			console.log('updateTournament catched error', e)
		}
	}

	function doubleTypeIsAllowed(regim) {
		return tournamentOptions[regim || currentRegim]?.settings?.participantsTypes?.double
	}

	if (error) { console.log(error) }

	return (
		<>
			<Header
				title={!wildcardValue ? t('Tournament creation') : t('Edition')}
				back
				backUrl={-1}
				border
				communityName={communityName}
			/>

			<main className="tournament-form">
				{
					isLoading || (uid && (Object.keys(communityOptions).length < 1 || !tierName)) ?
						<Loader />
						:
						<Formik
							initialValues={{
								name: !wildcardValue ? '' : name,
								venue: !wildcardValue ? '' : tournamentVenue || '',
								date: !wildcardValue ? '' : start_date,
								type: !wildcardValue || !doubleTypeIsAllowed() ? TYPE_SINGLES : type || TYPE_SINGLES,

								roundRobinRankingSystem: !wildcardValue ?
									POINTS_DIFFERENCE
									:
									round_robin_ranking_system || POINTS_DIFFERENCE,

								description: !wildcardValue ? '' : description || '',
								tier: !wildcardValue
									? tiers[0]
									: { label: tierName, value: tier },
								test: !wildcardValue ? true : false,
								prestageRating: showPrestageRating?.uid === uid && showPrestageRating?.active ? true : false
							}}
							onSubmit={async (values, { setFieldError }) => {
								if (uid) {
									await handleSubmitEdit(values, setFieldError)
								} else {
									await handleSubmitCreate(values, setFieldError)
								}
							}}
						>
							{({ values, errors, touched, handleSubmit, handleChange, setFieldError, setFieldValue }) => (
								<form
									onSubmit={handleSubmit}
									noValidate
									className="tournament-form__wrapper"
								>
									<Field
										id={'name'}
										name="name"
										onChange={handleChange}
										onClick={() => setFieldError('name', '')}
										type="text"
										value={values.name}
										placeholder={t('Name') + ' *'}
										as={Input}
										touched={touched.name}
										error={errors.name}
									/>

									<Field
										id={'venue'}
										onChange={(option) => setFieldValue('venue', option)}
										options={venues}
										value={values.venue}
										placeholder={t('Venue')}
										as={MySelect}
									/>

									<Field
										id={'date'}
										name={'date'}
										value={values.date}
										onChange={handleChange}
										onClick={() => setFieldError('date', '')}
										type="date"
										placeholder={t('Start date') + ' *'}
										as={Input}
										touched={touched.date}
										error={errors.date}
									/>

									<div className="tournament-form__types">
										<Field
											id="radio-single"
											name="type"
											value="singles"
											checked={values.type === TYPE_SINGLES}
											onChange={(e) => {
												if (wildcardValue) {
													setShowMessage(true)
													return
												}
												handleChange(e)
											}}
											label={t('Single tournament')}
											as={RadioButton}
											borderBottom
											styles={{
												margin: doubleTypeIsAllowed() ? '10px 0 0 0' : '10px 0'
											}}
										/>

										{doubleTypeIsAllowed() &&
											<Field
												id="radio-double"
												name="type"
												value="doubles"
												checked={values.type === TYPE_DOUBLES}
												onChange={(e) => {
													if (wildcardValue) {
														setShowMessage(true)
														return
													}
													handleChange(e)
												}}
												label={t('Double tournament')}
												as={RadioButton}
											/>
										}
									</div>

									<Field
										id={'tier'}
										onChange={(option) => {
											setFieldValue('tier', option)
											setCurrentRegim(option.value)
											if (!doubleTypeIsAllowed(option.value)) {
												setFieldValue('type', TYPE_SINGLES)
											}
										}}
										defaultValue={tournamentTiers[0]}
										options={tournamentTiers}
										value={values.tier}
										placeholder={t('Режим редактирования')}
										as={MySelect}
									/>

									{/* {
										tournamentOptions[currentRegim]?.settings?.testPeriod &&
											<Field
												id="checkbox-t2"
												name="test"
												checked={values.test}
												onChange={handleChange}
												label={t('Пробный')}
												as={SquareCheckbox}
											/>
									} */}

									<Field
										id="description"
										value={values.description}
										onChange={handleChange}
										height={'150px'}
										placeholder={t('Tournament description')}
										as={ReactTextarea}
									/>

									{
										tournamentOptions[currentRegim]?.stage?.preStageRating?.show &&
										<Field
											id="checkbox-t3"
											name="prestageRating"
											checked={values.prestageRating}
											onChange={handleChange}
											label={t('Редактирование предэтапного рейтинга')}
											as={Switcher}
										/>
									}

									<p className="tournament-form__description">
										{t('A method for determining places in a round-robin group when several participants have equal points')}
									</p>

									<Field
										id="radio-diff-games"
										name="rr-system"
										type="radio"
										value={POINTS_DIFFERENCE}
										onChange={() => setFieldValue('roundRobinRankingSystem', POINTS_DIFFERENCE)}
										checked={
											values.roundRobinRankingSystem === POINTS_DIFFERENCE
										}
										label={'По разнице геймов'}
										as={RadioButton}
										borderBottom
									/>

									<Field
										id="radio-diff-between"
										name="rr-system"
										type="radio"
										value={POINTS_DIFFERENCE_BETWEEN_DISPUTANTS}
										onChange={() => setFieldValue('roundRobinRankingSystem', POINTS_DIFFERENCE_BETWEEN_DISPUTANTS )}
										checked={
											values.roundRobinRankingSystem === POINTS_DIFFERENCE_BETWEEN_DISPUTANTS
										}
										label={'По разнице геймов между спорящими'}
										as={RadioButton}
									/>

									<Field
										id="radio-persentage"
										name="rr-system"
										type="radio"
										value={POINTS_PERCENTAGE}
										onChange={() => setFieldValue('roundRobinRankingSystem', POINTS_PERCENTAGE)}
										checked={
											values.roundRobinRankingSystem === POINTS_PERCENTAGE
										}
										label={'По проценту геймов'}
										as={RadioButton}
										borderBottom
									/>

									<Field
										id="radio-persentage-between"
										name="rr-system"
										type="radio"
										value={POINTS_PERCENTAGE_BETWEEN_DISPUTANTS }
										onChange={() => setFieldValue('roundRobinRankingSystem', POINTS_PERCENTAGE_BETWEEN_DISPUTANTS )}
										checked={
											values.roundRobinRankingSystem === POINTS_PERCENTAGE_BETWEEN_DISPUTANTS 
										}
										label={'По проценту геймов между спорящими'}
										as={RadioButton}
									/>

									<div className="tournament-form__button">
										<Button
											type={'submit'}
											title={!wildcardValue ? 'Создать' : 'Сохранить'}
											loading={requestIsActive}
										/>
									</div>
								</form>
							)}
						</Formik>
				}

				{showMessage &&
					<Popup title={'Действие недоступно'} close onClose={() => setShowMessage(false)}>
						<p className="tournament-form__popup-message">
							Запрещено менять тип турнира после его создания
						</p>
					</Popup>
				}
			</main>

			{
				!wildcardValue ?
					<BottomMenu />
					:
					<TournamentMenu />
			}
		</>
	)
}

export default memo(TournamentForm)