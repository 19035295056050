import { memo, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import './StageBuild.scss'
import {
	selectStageCondition,
	selectProcessedLevelsForBuildMode,
	selectImmutableProcessedLevels,
	selectReturnLevelsCondition,
	selectStructureIsChanged,
	selectForbiddenMessage,
} from '../../../redux/selectors'
import {
	useUpdateStageMutation
} from '../../../redux/stageService/stageApiSlice'
import { getNewUid, getParameters } from '../../../utils/functions'
import {
	setProcessedLevelsForBuildMode,
	addGroup,
	addLevel,
	removeLevel,
	setStageCondition,
	setReturnLevelsCondition,
	setStructureIsChanged,
	setShowForbiddenMessage
} from '../../../redux/stageService/stageSlice'
import IconButton from '../../../reusableComponents/IconButton/IconButton'
import DoubleButton from '../../../reusableComponents/DoubleButton/DoubleButton'
import { FILLED, NEW_GROUP } from '../../../utils/constants'
import { ReactComponent as DeleteIcon } from '../../../icons/bucket.svg'
import StageBuildGroup from '../StageBuildGroup/StageBuildGroup'
import NoContent from '../../../reusableComponents/NoContent/NoContent'
import NoGroups from '../../../reusableComponents/NoGroups/NoGroups'
import Popup from '../../../reusableComponents/Popup/Popup'

import { checkLevelsAndGroupsChanges, getGroupsAndNodesQuantity } from './externalFunctions'

function StageBuild() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const params = useParams()
	const wildcardValue = params['*']
	const { tournamentParams, stageNumber } = params
	const { tournamentUid } = getParameters(tournamentParams)

	const [changeStageParameters] = useUpdateStageMutation()

	const { stageStatus } = useSelector(selectStageCondition)
	const immutableLevels = useSelector(selectImmutableProcessedLevels)
	const currentLevelsForBuildMode = useSelector(selectProcessedLevelsForBuildMode)
	const conditionIsReturned = useSelector(selectReturnLevelsCondition)
	const structureIsChanged = useSelector(selectStructureIsChanged)
	const showForbiddenMessage = useSelector(selectForbiddenMessage)

	const levelsEquality = useMemo(() => {
		const { noChages, groupChanged } = checkLevelsAndGroupsChanges(immutableLevels, currentLevelsForBuildMode)

		return { noChages, groupChanged }
	}, [currentLevelsForBuildMode, immutableLevels])

	const groupsAndNodesQuantity = useMemo(() => {
		return getGroupsAndNodesQuantity(currentLevelsForBuildMode)
	}, [currentLevelsForBuildMode])

	useEffect(() => {
		if (structureIsChanged) {
			const container = document.getElementById('stage-build')

			if (!container) return
			// добавляем анимацию смещения вверх
			container.classList.add('reorder')

			// убираем отметку об изменении структуры
			dispatch(setStructureIsChanged(false))

			setTimeout(() => {
				container.classList.remove('reorder')
			}, 300) // Время должно соответствовать продолжительности анимации в CSS
		}
	}, [levelsEquality, conditionIsReturned])

	// Функция возвращающая группы по умолчанию для вкладки build
	function returnGroups() {
		dispatch(setProcessedLevelsForBuildMode(immutableLevels))

		dispatch(setReturnLevelsCondition(true))
	}

	const handleSaveFormedStage = useCallback(async () => {
		try {
			if (!levelsEquality.noChages) {
				const body = {
					levels: currentLevelsForBuildMode
				}

				await changeStageParameters({
					body,
					tournament_uid: tournamentUid,
					stageNumber
				}).unwrap()

				return
			}
		} catch (error) {
			console.log(error)
		}
	}, [currentLevelsForBuildMode, stageNumber,
		tournamentUid, changeStageParameters, levelsEquality])

	const handleAddLevel = useCallback(() => {
		const level = {
			'order_number': currentLevelsForBuildMode.length + 1,
			'name': null,
			'uid': getNewUid(),
			'groups': [
				{
					...NEW_GROUP,
					'order_number': null,
					'name': null,
					'new': true,
					'uid': getNewUid()
				}
			]
		}

		dispatch(addLevel({ level, levels: currentLevelsForBuildMode }))

		dispatch(setStageCondition({
			stageExists: true,
			stageStatus: FILLED
		}))

	}, [dispatch, currentLevelsForBuildMode])

	const handleAddGroup = useCallback((levelIndex) => {
		if (currentLevelsForBuildMode.length < 1) {
			handleAddLevel()
		} else {
			const body = {
				levels: currentLevelsForBuildMode,
				levelIndex,
				group: {
					...NEW_GROUP,
					'order_number': null,
					'name': null,
					'new': true,
					'uid': getNewUid()
				}
			}

			dispatch(addGroup(body))
		}
	}, [dispatch, currentLevelsForBuildMode, handleAddLevel])

	const handleRemoveLevel = useCallback((levelIndex, uid) => {
		// добавляем анимацию удаления
		document.getElementById(`stage-build-level-${uid}`).classList.add('remove')

		setTimeout(() => {
			dispatch(removeLevel({ levels: currentLevelsForBuildMode, levelIndex }))

			// отмечаем что структура изменилась, чтобы вызвать анимацию структуры
			dispatch(setStructureIsChanged(true))
		}, 500) // Время должно соответствовать продолжительности анимации в CSS
	}, [dispatch, currentLevelsForBuildMode])

	return (
		<div className="stage-build" id="stage-build" style={{ marginBottom: !levelsEquality.noChages ? '65px' : '' }}>

			{stageStatus === FILLED ?
				<>
					{
						currentLevelsForBuildMode?.map((level, levelIndex) =>
							<div
								key={level.uid}
								className={`stage-build__level ${currentLevelsForBuildMode.length < 2 ? 'level-margin' : ''}`}
								id={`stage-build-level-${level.uid}`}
							>
								{
									currentLevelsForBuildMode?.length > 1 &&
									<div className="stage-build__level-wrapper">
										<p className="stage-build__level-name">
											{`${t('Level').toUpperCase()} ${level?.order_number}`}
										</p>

										<DeleteIcon
											className="delete-icon"
											onClick={() => handleRemoveLevel(levelIndex, level.uid)}
										/>
									</div>
								}

								{level?.groups.length > 0 ?
									<>
										{
											level?.groups?.map((group, groupIndex) =>
												<div
													key={group?.uid}
													className="stage-build-group__container"
													id={`stage-build-group-${group.uid}`}
												>
													<StageBuildGroup
														levelIndex={levelIndex}
														groupIndex={groupIndex}
														group={group}
														nodesQuantity={groupsAndNodesQuantity?.nodes}
													/>
												</div>
											)
										}
									</>
									: level?.groups.length < 1 ?
										<NoGroups />
										:
										null
								}

								{/* максимальное количество уровней */}
								{groupsAndNodesQuantity?.groups < 26 &&
									<div className="stage-build__buttons-wrapper">
										<IconButton
											title="Группа"
											onClick={() => handleAddGroup(levelIndex)}
										/>

										{
											levelIndex + 1 === currentLevelsForBuildMode?.length && currentLevelsForBuildMode?.length < 26 &&
											<IconButton
												title="Уровень"
												onClick={() => handleAddLevel()}
											/>
										}
									</div>
								}
							</div>
						)
					}
				</>
				:
				<NoContent
					structure
					build
				/>
			}

			{
				!levelsEquality.noChages && currentLevelsForBuildMode &&
				wildcardValue === 'build' &&
				<DoubleButton
					titleBig="Сохранить"
					arrow
					typeSmall={'button'}
					typeBig={'button'}
					onClickBig={() => handleSaveFormedStage()}
					onClickSmall={() => returnGroups()}
					fixed
				/>
			}

			{
				currentLevelsForBuildMode && currentLevelsForBuildMode.length < 1 &&
				<div className="stage-build__buttons-wrapper">
					<IconButton
						title="Группа"
						onClick={() => handleAddGroup(0)}
					/>

					<IconButton
						title="Уровень"
						onClick={() => handleAddLevel()}
					/>
				</div>
			}

			{showForbiddenMessage &&
				<div className="stage-form__popup-message">
					<Popup
						title={'Действие недоступно'}
						onClose={() => dispatch(setShowForbiddenMessage(false))}
						close
					>
						<p className="stage-form__popup-message-text">Изменение типа и количества мест невозможны после начала игр в группе</p>
					</Popup>
				</div>
			}
		</div>
	)
}

export default memo(StageBuild)