import { createSlice } from '@reduxjs/toolkit'

import { CREATE, EMPTY_NODE } from '../../utils/constants'
import { getSessionStorageItem, parseObject, setItemInSessionStorage, updateGroupsAndLevelsOrderNumber } from '../../utils/functions'

const stageSlice = createSlice({
	name: 'stage',
	initialState: {
		initialStage: {},
		processedStage: {},
		immutableProcessedLevels: [],
		processedLevelsForBuildMode: [],
		playersList: [],
		chosenPlayerInList: {},
		dragState: {},
		currentStageNumber: getSessionStorageItem('currentStage') || 1,
		showPrestageRating: parseObject('showPrestageRating', getSessionStorageItem) || null,
		stageCondition: {
			stageExists: false,
			stageStatus: CREATE,
		},
		cleanGroupNodesCount: false,
		structureIsChanged: false,
		totalQuantityNodesInGroups: 0,
		showForbiddenMessage: false
	},
	reducers: {
		setInitialStage(state, action) {
			state.initialStage = action.payload
		},
		setProcessedStage(state, action) {
			state.processedStage = action.payload
		},
		setImmutableProcessedLevels(state, action) {
			state.immutableProcessedLevels = action.payload
		},
		setProcessedLevelsForBuildMode(state, action) {
			state.processedLevelsForBuildMode = action.payload
		},
		addParticipant(state, action) {
			const { levelIndex, groupIndex, node } = action.payload
			state.processedStage.levels[levelIndex].groups[groupIndex].nodes.push(node)
		},
		dragParticipant(state, action) {
			const {
				sourceLevelIndex,
				endLevelIndex,
				endGroupIndex,
				sourceGroupIndex,
				endIndex,
				sourceIndex,
				movedNode,
				replacedNode
			} = action.payload

			state.processedStage.levels[sourceLevelIndex].groups[sourceGroupIndex].nodes[sourceIndex] = movedNode
			state.processedStage.levels[endLevelIndex].groups[endGroupIndex].nodes[endIndex] = replacedNode
		},
		updateGroupNodes(state, action) {
			const { levels, levelIndex, groupIndex, nodeIndex, addedNode } = action.payload

			state.processedStage.levels = levels.map((lvl, lvlIndex) => ({
				...lvl,
				groups: lvl.groups.map((grp, grpIndex) => ({
					...grp,
					nodes: grp.nodes.map((item, itemIndex) => {
						if (((addedNode.playerUid && addedNode.playerUid === item.playerUid)
							|| (addedNode.doubleUid && addedNode.doubleUid === item.doubleUid)
							|| (!addedNode.playerUid && !addedNode.doubleUid && addedNode.name === item.name))) {
							// очищаем старое место где был участник
							return {
								...item,
								...EMPTY_NODE,
								dependsOnUid: null
							}
						} else if (lvlIndex === levelIndex && grpIndex === groupIndex && itemIndex === nodeIndex) {
							// вставляем участника в целевое место
							return {
								...item,
								participant: addedNode.participant  || {},
								playerUid: addedNode.playerUid || null,
								doubleUid: addedNode.doubleUid || null,
								dependsOnUid: addedNode.nodeUid || null,
								placed: true,
								rating: addedNode.rating || null,
								name: addedNode.name  || null
							}
						} else {
							return item
						}
					})
				}))
			}))
		},
		updateGroupName(state, action) {
			const { levelIndex, groupIndex, groupName } = action.payload
			state.processedLevelsForBuildMode[levelIndex].groups[groupIndex].name = groupName
		},
		updateLevelName(state, action) {
			const { levelIndex, levelName } = action.payload
			state.processedLevelsForBuildMode[levelIndex].name = levelName
		},
		addLevel(state, action) {
			const { levels, level } = action.payload
			let updatedLevels = [...levels]
			updatedLevels.push(level)
			updatedLevels = updateGroupsAndLevelsOrderNumber(updatedLevels)

			state.processedLevelsForBuildMode = updatedLevels
		},
		removeLevel(state, action) {
			const { levels, levelIndex} = action.payload
			let updatedLevels = [...levels]

			updatedLevels.splice(levelIndex, 1)
			updatedLevels = updateGroupsAndLevelsOrderNumber(updatedLevels)

			state.processedLevelsForBuildMode = updatedLevels
		},
		addGroup(state, action) {
			const { levels, levelIndex, group } = action.payload
			let updatedLevels = [...levels]
			const updatedLevel = {...updatedLevels[levelIndex]}
			const updatedGroups = [...updatedLevel.groups]

			updatedGroups.push(group)
			updatedLevel.groups = updatedGroups
			updatedLevels[levelIndex] = updatedLevel
			updatedLevels = updateGroupsAndLevelsOrderNumber(updatedLevels)

			state.processedLevelsForBuildMode = updatedLevels
		},
		updateGroup(state, action) {
			const { levelIndex, groupIndex, group } = action.payload
			state.processedLevelsForBuildMode[levelIndex].groups[groupIndex] = group
		},
		removeGroup(state, action) {
			const { levels, levelIndex, groupIndex } = action.payload
			let updatedLevels = [...levels]
			const updatedLevel = {...levels[levelIndex]}

			if (updatedLevel.groups.length < 2) {
				updatedLevels.splice(levelIndex, 1)
			} else {
				const updatedGroups = [...levels[levelIndex].groups]

				updatedGroups.splice(groupIndex, 1)
				updatedLevel.groups = updatedGroups
				updatedLevels[levelIndex] = updatedLevel
			}

			updatedLevels = updateGroupsAndLevelsOrderNumber(updatedLevels)

			state.processedLevelsForBuildMode = updatedLevels
		},
		setPlayersList(state, action) {
			state.playersList = action.payload
		},
		setChosenPlayerInList(state, action) {
			state.chosenPlayerInList = action.payload
		},
		setDragState(state, action) {
			state.dragState = action.payload
		},
		setCurrentStageNumber(state, action) {
			setItemInSessionStorage('currentStage', action.payload)
			state.currentStageNumber = action.payload
		},
		setShowPrestagerating(state, action) {
			setItemInSessionStorage('showPrestageRating', JSON.stringify(action.payload))
			state.showPrestageRating = action.payload
		},
		setStageCondition(state, action) {
			const { stageStatus, stageExists } = action.payload
			state.stageCondition = {
				stageStatus,
				stageExists
			}
		},
		setReturnLevelsCondition(state, action) {
			state.cleanGroupNodesCount = action.payload
		},
		setStructureIsChanged(state, action) {
			state.structureIsChanged = action.payload
		},
		setTotalQuantityNodesInGroups(state, action) {
			state.totalQuantityNodesInGroups = action.payload
		},
		setShowForbiddenMessage(state, action) {
			state.showForbiddenMessage = action.payload
		}
	}
})

export const {
	setInitialStage,
	setProcessedStage,
	setImmutableProcessedLevels,
	setProcessedLevelsForBuildMode,
	addParticipant,
	updateGroupNodes,
	updateGroupName,
	updateLevelName,
	addLevel,
	addGroup,
	updateGroup,
	removeLevel,
	removeGroup,
	dragParticipant,
	setStageLevels,
	setPlayersList,
	setChosenPlayerInList,
	setDragState,
	setCurrentStageNumber,
	setShowPrestagerating,
	setStageCondition,
	setReturnLevelsCondition,
	setStructureIsChanged,
	setTotalQuantityNodesInGroups,
	setShowForbiddenMessage
} = stageSlice.actions
export default stageSlice.reducer