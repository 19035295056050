import { memo, useState, useCallback, useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'

import './StageGroup.scss'
import StageGroupIcon from '../../../reusableComponents/StageGroupIcon/StageGroupIcon'
import {
	TYPE_DOUBLES,
	TYPE_SINGLES,
	GROUP_TYPE_OLYMPIC_PLUS,
	EMPTY_NODE
} from '../../../utils/constants'
import GroupPlayer from '../GroupPlayer/GroupPlayer'
import Popup from '../../../reusableComponents/Popup/Popup'
import { selectProcessedStage, selectChosenPlayerInList, selectPlayersList, selectTournament, selectTournamentOptions } from '../../../redux/selectors'
import { generateGroupOrLevelsName } from '../../../utils/functions'
import { nodeInPlayngGroup } from '../../../utils/functions2'
import {
	updateGroupNodes,
	setChosenPlayerInList,
	setPlayersList,
	setShowForbiddenMessage
} from '../../../redux/stageService/stageSlice'

import { placeParticipants } from './externalFunctions'

function StageGroup({ levelIndex, groupIndex }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const stageState = useSelector(selectProcessedStage)
	const playersList = useSelector(selectPlayersList)
	const { type } = useSelector(selectTournament)
	const chosenPlayerInList = useSelector(selectChosenPlayerInList)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const [replacedPlayerIndex, setReplacedPlayerIndex] = useState('')
	const [popupIsOpened, setPopupIsOpened] = useState(false)
	const levels = stageState?.levels

	const group = useMemo(() => {
		return levels[levelIndex]?.groups[groupIndex]
	}, [levels, levelIndex, groupIndex])

	const openPopup = useCallback(() => {
		setPopupIsOpened(true)

		document.body.style.overflow = 'hidden'
	}, [])

	const closePopup = useCallback(() => {
		setPopupIsOpened(false)

		document.body.style.overflow = 'auto'
	}, [])

	const replacePlayerInGroup = useCallback((nodeIndex, replacingNode) => {
		const replacingFromParticipantsList = chosenPlayerInList?.index >= 0 ? true : false

		// нода из которой добавляется участник
		const addedNode = replacingFromParticipantsList ?
			chosenPlayerInList.player : replacingNode

		// нода куда добавится участник
		const replacedNode = { ...levels[levelIndex].groups[groupIndex].nodes[nodeIndex] }

		if ((addedNode.playerUid && addedNode.playerUid === replacedNode.playerUid)
			|| (addedNode.doubleUid && addedNode.doubleUid === replacedNode.doubleUid)
			|| (!addedNode.playerUid && !addedNode.doubleUid && addedNode.name === replacedNode.name)) {
			//если заменяемая и добавляемая ноды имеют одинакового участника или мнемонику, то ничего не делаем
			return
		}

		dispatch(updateGroupNodes({
			levels,
			levelIndex,
			groupIndex,
			nodeIndex,
			addedNode
		}))

		const updatedParticipantsList = placeParticipants(playersList, addedNode, replacedNode)

		dispatch(setPlayersList(updatedParticipantsList))
		dispatch(setChosenPlayerInList({}))
	}, [dispatch, levels,
		chosenPlayerInList, playersList, levelIndex, groupIndex])

	const handleNameInPopup = useCallback((node) => {
		const groupOfNodeStartedToPlay = nodeInPlayngGroup(node, stageState)

		if (groupOfNodeStartedToPlay) {
			dispatch(setShowForbiddenMessage(true))
			return
		}

		replacePlayerInGroup(replacedPlayerIndex, node)
		setReplacedPlayerIndex('')

		closePopup()
	}, [replacedPlayerIndex, closePopup, replacePlayerInGroup, stageState, dispatch])

	const handleNameInGroup = useCallback((nodeIndex, node) => {
		if (!options?.showParticipantsList) return

		const movePlayer = Object.keys(chosenPlayerInList).length !== 0
		const isOlympicPlus = group?.type === GROUP_TYPE_OLYMPIC_PLUS

		if (movePlayer && !isOlympicPlus) {
			replacePlayerInGroup(nodeIndex, node)
		} else if (isOlympicPlus && movePlayer) {
			replacePlayerInGroup(nodeIndex, node)

			dispatch(setChosenPlayerInList({}))
		} else if (!node?.disabled) {
			setReplacedPlayerIndex(nodeIndex)

			openPopup()
		} else {
			return
		}
	}, [replacePlayerInGroup, openPopup, chosenPlayerInList, dispatch, group?.type, options])


	// Функция возвращающая количество игроков в Группе в двух вариантах (4 или 3/4)
	function getPlacedPlayersNumberVSEmptyPlaces() {
		const groupNodesQuantity = group?.nodes?.length

		// незаблокированные ноды
		const activeNodes = group?.nodes?.filter(node => !node.disabled)

		// ноды с игроками или хотя бы с именем ноды
		const filledNodes = group?.nodes?.filter((node) => {
			return node?.playerUid || node?.doubleUid || node?.name
		})

		if (filledNodes.length === groupNodesQuantity) {
			return groupNodesQuantity
		} else {
			return `${filledNodes?.length}/${activeNodes.length}`
		}
	}

	function getPairsOrder() {
		// нумерация матчей для олимпик+
		const pairsNumber = group?.nodes?.length / 2 || 0
		const pairsArray = Array.from({ length: pairsNumber }, (_, index) => index + 1)
		return pairsArray
	}

	return (
		<>
			<div className="stage-group">
				<div className={`stage-group__header ${group?.nodes?.length < 1 ? 'no-border' : ''}`}>
					<div className="stage-group__name-wrapper">
						<p className="stage-group__header-name-group">
							{generateGroupOrLevelsName(group.order_number - 1)}
						</p>
					</div>

					<div className="group__header-wrapper">
						<StageGroupIcon type={group?.type} />

						<p className="stage-group__header-counter">
							{getPlacedPlayersNumberVSEmptyPlaces()}
						</p>

					</div>
				</div>

				{group?.nodes?.length > 0 &&
					<div className="stage-group__participants">
						<div className="stage-group__participants-order">
							{group.type === GROUP_TYPE_OLYMPIC_PLUS &&
								getPairsOrder().map((number) => (
									<div key={number}>{number}</div>
								))
							}
						</div>

						<Droppable
							key={groupIndex}
							droppableId={`${levelIndex}-${groupIndex}`}
						>
							{
								(provided) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										className="stage-group__participants-container"
									>
										{
											group.nodes.map((node, index) => (
												<GroupPlayer
													key={node.nodeUid}
													handleName={handleNameInGroup}
													node={node}
													position={[levelIndex, groupIndex, index]}
													group={group}
												/>
											))
										}

										<div style={{ display: 'none' }}>
											{provided.placeholder}
										</div>
									</div>
								)
							}
						</Droppable>
					</div>
				}
			</div>

			{
				popupIsOpened &&
				<Popup
					onClose={closePopup}
					title={t('Participants')}
					close
				>
					<div
						className={`stage-group__popup-participants-item empty-node ${
							type === TYPE_SINGLES ? 'single' : 'double'
						}`}
						onClick={() => handleNameInPopup(EMPTY_NODE)}
					>
						<p className="stage-group__popup-participants-name">(-)</p>
						<span></span>
					</div>
					{
						playersList?.map((node, index) => (
							<Fragment key={index}>
								{
									!node.disabled &&
									<div
										className={
											`stage-group__popup-participants-item ${!node.placed ?
												'notselected'
												:
												''
											} ${type === TYPE_SINGLES ? 'single' : 'double'} ${nodeInPlayngGroup(node, stageState) ? 'disabled' : ''}`
										}
										onClick={() => handleNameInPopup(node)}
									>
										{
											type === TYPE_SINGLES &&
											<div className="stage-group__popup-participants-name">
												<p className="stage-group__popup-doubles-rating">
													{index + 1}
												</p>

												{node?.participant?.formedName ?
													`${node?.participant?.formedName} [${node.name}]`
													:
													`${node.name}`
												}
											</div>
										}

										{
											type === TYPE_DOUBLES &&
											<div className="stage-group__popup-participants-doubles ">
												<p className="stage-group__popup-doubles-rating">
													{index + 1}
												</p>

												{
													node?.doubleUid === null ?
														<p className="stage-group__popup-doubles-name">
															{node?.name}
														</p>
														:
														<div className="stage-group__popup-participants-doubles-wrapper">
															<p className="stage-group__popup-participants-name--first">
																{node?.participant.firstPlayerName}
															</p>

															<p className="stage-group__popup-participants-name--second">
																{node?.participant.secondPlayerName}
															</p>
														</div>
												}

												<span>{node?.doubleUid && `[${node?.name}]`}</span>
											</div>
										}
									</div>
								}
							</Fragment>
						))
					}
				</Popup>
			}
		</>
	)
}

export default memo(StageGroup)