import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './TournamentGrids.scss'
import { useGetStageQuery } from '../../redux/stageService/stageApiSlice'
import { selectAuth, selectStageCondition, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { useProcessStageData } from '../../hooks/stageHooks'
import { getParameters } from '../../utils/functions'
import { FILLED, GROUP_TYPE_RR, GROUP_TYPE_OLYMPIC_PLUS, CREATE, FULFILLED } from '../../utils/constants'
import RoundRobinGrid from '../../extendedComponents/RoundRobin/RoundRobinGrid/RoundRobinGrid'
import OlympicPlusGrid from '../../extendedComponents/OlympicPlusGrid/OlympicPlusGrid'
import NotPlayingGrid from '../../extendedComponents/NotPlayingGrid/NotPlayingGrid'
import NoContent from '../../reusableComponents/NoContent/NoContent'
import CombineTournamentMenu from '../../reusableComponents/CombineTournamentMenu/CombineTournamentMenu'
import Loader from '../../reusableComponents/Loader/Loader'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import NoGroups from '../../reusableComponents/NoGroups/NoGroups'
import TournamentGridsPrint from '../../print/TournamentGridsPrint/TournamentGridsPrint'
import StageGeneration from '../../extendedComponents/Stage/StageGeneration/StageGeneration'
import RemoveStageButton from '../../reusableComponents/RemoveStageButton/RemoveStageButton'

import { groupLengthCheck } from './externalFunctions'

function TournamentGrids({ tournamentStartDate, category, tournamentRefetch }) {
	const { t } = useTranslation()
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { authorized, guestCode } = useSelector(selectAuth)
	const { stages_count, name, players, doubles } = useSelector(selectTournament)
	const { stageStatus } = useSelector(selectStageCondition)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const participants = players || doubles

	const { data: stageData = {}, status } = useGetStageQuery({
		tournament_uid: tournamentUid,
		stageNumber
	}, {
		skip: !stages_count || stages_count < 1
			|| stageNumber < 1 || stageNumber > stages_count,
		refetchOnMountOrArgChange: stages_count > 0
	})

	useProcessStageData(stageData, status)

	// сортировка участников, когда все матчи сыграны
	// function getGroup(group) {
	// 	const { nodes, results } = sortByRanking(group)

	// 	return { ...group, nodes, results }
	// }

	// можно ли показать сетки. name проверяется, чтобы убедиться, что турнир уже загрузился
	const firstCondition = name && stages_count > 0 && status === FULFILLED && stageData?.levels?.length > 0

	// показ NoContent для режима, у которого есть вкладка Структура
	const conditionForWideRegims = options?.modes?.tabsAreVisible && (
		(stages_count > 0 && status === FULFILLED && stageData?.levels?.length < 1) || stages_count < 1
	)

	// показ NoContent для режима, у которого нет вкладки Структура
	const conditionForLimitedRegim = participants?.length < 1 && !options?.modes?.tabsAreVisible

	const secondCondition = (name && options?.modes && (
		conditionForWideRegims || conditionForLimitedRegim)
	) || !authorized || guestCode

	// показ Автогенерации для режима, у которого нет вкладки Структура
	const thirdCondition = name && participants && participants.length > 0
		&& options?.modes && !options?.modes?.tabsAreVisible && (
		(stages_count < 1 && stageNumber < 2) || (stages_count > 1 && stageNumber > 1 && stageData?.levels?.length < 1)
	)  && authorized && !guestCode


	// показ меню этапов
	const forthCondition = name && stages_count > 0 && options?.modes && !options?.modes?.tabsAreVisible && authorized && !guestCode

	return (
		<>
			<HeaderTournament
				date={tournamentStartDate}
				category={category}
				copyLink={authorized && !guestCode ? true : false}
				printComponent={
					<TournamentGridsPrint />
				}
				background
			/>

			<main className={
				`tournament-grids__container ${stageStatus === FILLED ? 'filled' : ''}`
			}>
				<div className="tournament-grids">
					{
						firstCondition ?
							stageData?.levels?.map((level, levelIndex) => {
								return (
									<React.Fragment key={levelIndex}>
										{
											stageData?.levels.length > 1 && groupLengthCheck(stageData) &&
											<p className="tournament-grids__level">
												{`${t('Level')} ${levelIndex + 1}`}
											</p>
										}

										{
											level?.groups.length > 0 ?
												<>
													{
														level?.groups?.map((grp, index) =>
															<React.Fragment key={index}>
																{
																	grp?.type === GROUP_TYPE_RR ?
																		<RoundRobinGrid
																			group={grp}
																		/>
																		:
																		grp?.type === GROUP_TYPE_OLYMPIC_PLUS ?
																			<OlympicPlusGrid
																				group={grp}
																			/>
																			:
																			<NotPlayingGrid
																				group={grp}
																			/>
																}
															</React.Fragment>
														)}
												</>
												:
												<NoGroups />
										}
									</React.Fragment>
								)
							})
							: secondCondition ?
								<NoContent
									table={'Сеток'}
								/>
								: thirdCondition ?
									<StageGeneration
										tournamentRefetch={tournamentRefetch}
									/>
									:
									<Loader />
					}
				</div>

				{forthCondition &&
					<RemoveStageButton tournamentRefetch={tournamentRefetch} />
				}
			</main>

			<CombineTournamentMenu />
		</>
	)
}

export default memo(TournamentGrids)