import { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation, Routes, Route, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getParameters } from '../../utils/functions'
import { selectAuth, selectCommunity } from '../../redux/selectors'
import { setGuestCode } from '../../redux/authService/authSlice'
import { useGetTournamentQuery } from '../../redux/singleTournamentService/singleTournamentApiSlice'
import { useGetCommunityQuery } from '../../redux/communityService/communityApiSlice'
import { setTournamentData, setTournamentOptions } from '../../redux/singleTournamentService/singleTournamentSlice'
import { setNoSortedParticipants, setParticipants } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import TournamentParticipants from '../TournamentParticipants/TournamentParticipants'
import TournamentInfo from '../TournamentInfo/TournamentInfo'
import TournamentStructure from '../TournamentStructure/TournamentStructure'
import TournamentGrids from '../TournamentGrids/TournamentGrids'
import TournamentSchedule from '../TournamentSchedule/TournamentSchedule'
import TournamentResult from '../TournamentResult/TournamentResult'
import Match from '../Match/Match'
import PlayerForm from '../../views/PlayerForm/PlayerForm'
import Missing from '../../views/Missing/Missing'

import { dateFormatting } from './externalFunctions'

function Tournament() {
	const location = useLocation()
	const dispatch = useDispatch()
	const params = useParams()
	const { tournamentUid, guest } = getParameters(params.tournamentParams)
	const { authorized } = useSelector(selectAuth)
	const { communityUid } = useSelector(selectCommunity)
	const [codeForGuest, setCodeForGuest] = useState()

	const { data: tournament = {}, refetch } = useGetTournamentQuery(
		{ tournament_uid: tournamentUid }, { skip: (guest && !codeForGuest) || (!authorized && !guest) }
	)

	const { data: community = {} } = useGetCommunityQuery(
		{ community_uid: communityUid },
		{ skip: Object.keys(tournament).length === 0 || guest || !authorized || !communityUid }
	)

	const category = useMemo(() => {
		if (Object.keys(community).length !== 0) {
			community.categories.filter((el) => {
				return el.uid === tournament?.uid
			})
		}
	}, [community, tournament])

	// Смотрим, авторизован ли организатор или нет или это гость
	useEffect(() => {
		if (guest) {
			dispatch(setGuestCode(guest))

			setCodeForGuest(guest)
		} else if (!guest) {
			dispatch(setGuestCode(null))

			setCodeForGuest(null)
		}
	}, [guest])

	useEffect(() => {
		if (Object.keys(tournament).length !== 0) {
			dispatch(setTournamentData(tournament))
			dispatch(setTournamentOptions(tournament.tier))
			dispatch(setParticipants({ participants: tournament?.doubles || tournament?.players || [] }))
			dispatch(setNoSortedParticipants(tournament?.doubles || tournament?.players || []))
		}
	}, [tournament, tournamentUid]) // не ставить dispatch в зависимости, сломается обновление

	function tournamentRefetch() {
		refetch()
	}

	if (!authorized && !guest) {
		const queryParams = new URLSearchParams()
		queryParams.set('redirect', location.pathname)
		queryParams.set('tournament', 'true')

		const queryString = queryParams.toString()

		return <Navigate to={`/login?${queryString}`} />
	}

	return (
		<>
			<Routes>
				<Route path="draws/:stageNumber" element={
					<TournamentGrids
						tournamentStartDate={dateFormatting(tournament.start_date)}
						category={category}
						tournamentRefetch={tournamentRefetch}
					/>}
				/>

				<Route path="schedule/:stageNumber" element={
					<TournamentSchedule
						tournamentStartDate={dateFormatting(tournament.start_date)}
						category={category}
						tournamentRefetch={tournamentRefetch}
					/>}
				/>

				<Route path="structure/:stageNumber/*" element={
					<TournamentStructure
						tournamentRefetch={tournamentRefetch}
						tournamentStartDate={dateFormatting(tournament.start_date)}
						category={category}
					/>}
				/>

				<Route path="participants" element={
					<TournamentParticipants
						tournamentRefetch={tournamentRefetch}
						tournamentStartDate={dateFormatting(tournament.start_date)}
					/>}
				/>

				<Route path="info" element={
					<TournamentInfo
						community={community}
						tournamentStartDate={dateFormatting(tournament.start_date)}
					/>}
				/>

				<Route path="result" element={
					<TournamentResult
						tournamentStartDate={dateFormatting(tournament.start_date)}
					/>}
				/>

				<Route path=":tab/:stageNumber/match" element={<Match />} />

				<Route path="participants/form" element={<PlayerForm />} />

				<Route path="*" element={<Missing />} />
			</Routes>
		</>
	)
}

export default Tournament